enum LabelType {
  Midi = 0,
  Gross = 1,
  MidiAPlus = 2,
  GrossAPlus = 3,
  Package = 4,
  GASPackage = 5,
  /**
   * A buyback order for a small package (e.g. phone)
   */
  BuybackMini = 6,
  /**
   * A buyback order for a large package (e.g. laptop)
   */
  BuybackLarge = 7,
  /**
   * PostPac Priority with RMP (Personal Registered Mail)
   */
  PostPacPriorityRMP = 8,
  /**
   * A Post Einschreiben & RMP (Registered & Personal Mail)
   */
  APostRegisteredAndRMP = 9,
  /**
   * A Post Einschreiben (Registered Mail)
   */
  APostRegistered = 10,
  /**
   * PostPac Priority with Signature (Registered Mail)
   */
  PostPacPriorityRegistered = 11,
}

export default LabelType;
